.logo {
  max-width: 27%;
  float: left;
  box-shadow: 0 5 0 10px;
  margin-top: 16px;
}
.headermenu {
  background: #fff;

  height: none;

  opacity: rgba(0, 0, 0, 0, 0.5);
  border-bottom: none;
  float: right;

  padding-top: 4px;
  display: block;
  rotate: 0deg;
  padding-bottom: 5px;
}
.headerflagmenu > .ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  background: none;
}
.headerflagmenu {
  line-height: 10px;
}
.ant-menu-horizontal > .ant-menu-submenu-selected {
  background: none;
}
.ant-layout-header .site-layout-background {
  width: '100%';
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  border-radius: 2px;
  padding-top: 7px;
}
.site-layout-background {
  background: #fff;

  border-bottom: 3px solid #007bff;
  padding-bottom: 10px;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 760px) {
  .logo {
    width: 25%;
  }
}
