#components-layout-demo-custom-trigger .trigger {
  font-size: 15px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.2s;
}
.welcomeMessage {
  font-size: 36px;

  font-style: inherit;
  font-weight: 400px;
  background: #007bff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .ant-input-search-enter-button
  + .ant-input-group-addon
  .ant-input-search-button,
.ant-input-search-enter-button
  input
  + .ant-input-group-addon
  .ant-input-search-button {
  background: #007bff;
  border-radius: 0 20px 0 20px;
  z-index: 2;
  border: 1px solid #007bff;
} */

/* .ant-input-group > .ant-input:first-child {
  z-index: 1;
  width: 110%;
  border: 1px solid #007bff;
}
.ant-input-group > .ant-input:first-child:hover {
  border: 1px solid #007bff;
  box-shadow: none;
} */

/* .ant-input-search-enter-button input:focus {
  box-shadow: none;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #b61717;
  border-color: #b61717;
  border: 1px solid #b61717;
} */

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #e6f6ff;
  border-right: 3px solid #007bff;
}
.ant-menu-root.ant-menu-inline {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-right: none;
}
.ant-menu-root.ant-menu-vertical {
  box-shadow: none;
  max-width: 251px;
  border-right: none;
}

.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
  border-radius: 5px 5px 5px 5px;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 1px solid #007bff;
}

.site-layout .site-layout-background {
  background: #007bff;
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}

.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background: #007bff;
}
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background: #007bff;
}
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background: #007bff;
}
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background: #007bff;
}

.ant-menu-item > a:hover {
  color: #007bff;
}
.ant-menu-item-selected > a {
  color: #007bff;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu-submenu-title:hover {
  color: #007bff;
}

.ant-menu-sub.ant-menu-inline {
  max-width: 249px;
}

.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(45deg) translateX(-2px);
  transform: rotate(45deg) translateX(-2px);
  background: #999;
}
.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(-45deg) translateX(2px);
  transform: rotate(-45deg) translateX(2px);
  background: #999;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  background: #e6f6ff;
}

.ant-menu-submenu-arrow:hover {
  background: #999;
}
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title:hover {
  background: #e6f6ff;
}
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title:hover {
  background: #e6f6ff;
}
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title:hover {
  background: #e6f6ff;
}

.hamburgerMenu {
  overflow: 'auto';
  left: 0;
  background-color: '#fff';
  width: 400px;
  max-width: 400px;
  min-width: 400px;
  background: #fff;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: none;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: none;
}
/* .ant-layout-sider-light .ant-layout-sider-trigger {
  color: #e6f6ff;
  font-size: 15px;
  background: #fff;
} */

/* .ant-menu-sub.ant-menu-inline {
  background-color: #212121;
} */
/* .ant-menu-submenu-title {
  color: #999;
} */
.menuBar {
  display: none;
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 0;
}
.ant-menu-inline .ant-menu-submenu-title:hover {
  color: #007bff;
}
:root {
  scrollbar-face-color: #999; /* Firefox 63 compatibility */
  scrollbar-color: #999 #fff;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #5a5a5a;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5a5a5a;
}
.ant-menu-submenu-selected {
  background: #e6f6ff;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 760px) {
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #b4b4b4;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b4b4b4;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b4b4b4;
  }
  .ant-menu-inline .ant-menu-submenu-title:hover {
    color: #999;
  }
  .ant-menu-submenu-selected {
    background: #fff;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: none;
    border-right: 4px solid #007bff;
  }
  .ant-menu-sub.ant-menu-inline {
    color: #fff;
  }
  .site-layout-background {
    display: none;
  }
  .hamburgerMenu {
    display: none;
  }
  .ant-menu-sub.ant-menu-inline {
    background: #fff;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
    background-color: #fff;
  }
  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    background: none;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    background: none;
  }

  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title:hover {
    background: none;
  }
  .ant-menu-inline-collapsed
    > .ant-menu-submenu
    > .ant-menu-submenu-title:hover {
    background: none;
  }
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title:hover {
    background: none;
  }

  .menuBar {
    display: initial;
    position: relative;
    height: 50px;
    max-width: 100%;
    background-color: #fff;
  }
  .barsMenu {
    height: 50px;

    background-color: #fff;
    border: none;
    float: left;
  }
  .barsMenuX {
    height: 50px;
    width: 50px;
    max-width: 10vh;
    background-color: #fff;
    margin-left: -23px;
    margin-top: -24px;
    float: right;
    padding-left: 33px;
    padding-top: 10px;
    border: none;
    box-shadow: none;
  }

  .barsBtn {
    height: 40px;
    width: 10px;
    float: left;
  }
  .barsBtnx {
    height: 40px;
    width: 10px;
    float: left;
    margin-top: 4px;
    margin-left: -3px;
    font-size: 25px;
    color: #007bff;
  }
  .barslogo {
    height: 40px;
    width: 70px;

    position: absolute;
    left: 40%;
    padding-top: 10px;

    background-color: #fff;
  }

  .content {
    background-color: lavenderblush;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .dateDashboard {
    width: 200px;
  }
  .filterButton {
    width: 100px;
    float: left;
    margin-left: 137px;
  }
  .partnerBranch {
    width: 400px;
  }
  .hours {
    width: 150px;
  }
  .icon {
    font-size: 13px;
  }
  .h4 {
    font-size: 12;
    margin-left: 12px;
    width: 100px;
  }
  .number {
    font-size: 12;
    margin-left: 100px;
  }

  .partnerSelector {
    width: 160px;
  }
  .dateSelectorStart {
    width: 195px;
    padding-left: 70px;
  }
  .dateSelectorEnd {
    width: 260px;
    padding-left: 130px;
  }
  .filterButton {
    margin-left: 200px;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .partnerSelector {
    width: 160px;
  }
  .dateSelectorStart {
    width: 195px;
    padding-left: 30px;
  }
  .dateSelectorEnd {
    width: 260px;
    padding-left: 84px;
  }
  .filterButton {
    margin-left: 140px;
  }
  .dateDashboard {
    margin-left: 140px;
  }
  .filterButton {
    width: 200px;
    margin-left: 200px;
  }
  .partnerBranch {
    width: 400px;
  }
}
